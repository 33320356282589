import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import { baseImageUrl, request } from "../../api/request";
import { useQueries } from "@tanstack/react-query";
import { GetErrorHandler } from "../../helper/GetErrorHandlerHelper";
import goma1 from './PoweredbyGoma.png'
import goma2 from './Asset 13.png'

import PublicIcon from '@mui/icons-material/Public';
const getOffersRequest = () => {
  return request({
    url: "/publicfeatures",
  });
};

const SkeltonLoader = () => {
  

  return (

    <Box
      sx={{
        pt: 8,
        pl: 2,
        pr: 2,
   }}
      

    >
      <Box
        sx={{
          width: "65%",
          margin: "0 auto 24px auto",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={"300px"}
              sx={{
                borderRadius: "12px",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container rowSpacing={2} columnSpacing={1} sx={{ mb: "74px" }}>
        <Grid item xs={6} md={4}>
          <Box
            sx={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"200px"}
                animation={"wave"}
                sx={{ borderRadius: "12px", mb: 2 }}
              />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                color: "#1C5F55",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"30px"}
                animation={"wave"}
                sx={{ borderRadius: "12px" }}
              />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box
            sx={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"200px"}
                animation={"wave"}
                sx={{ borderRadius: "12px", mb: 2 }}
              />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                color: "#1C5F55",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"30px"}
                animation={"wave"}
                sx={{ borderRadius: "12px" }}
              />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box
            sx={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"200px"}
                animation={"wave"}
                sx={{ borderRadius: "12px", mb: 2 }}
              />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                color: "#1C5F55",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"30px"}
                animation={"wave"}
                sx={{ borderRadius: "12px" }}
              />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box
            sx={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"200px"}
                animation={"wave"}
                sx={{ borderRadius: "12px", mb: 2 }}
              />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                color: "#1C5F55",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"30px"}
                animation={"wave"}
                sx={{ borderRadius: "12px" }}
              />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box
            sx={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"200px"}
                animation={"wave"}
                sx={{ borderRadius: "12px", mb: 2 }}
              />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                color: "#1C5F55",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"30px"}
                animation={"wave"}
                sx={{ borderRadius: "12px" }}
              />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box
            sx={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"200px"}
                animation={"wave"}
                sx={{ borderRadius: "12px", mb: 2 }}
              />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                color: "#1C5F55",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"30px"}
                animation={"wave"}
                sx={{ borderRadius: "12px" }}
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ width: { xs: "100%", md: "60%", margin: "0 auto 50px auto" } }}
      >
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"30px"}
          animation={"wave"}
          sx={{ borderRadius: "12px" }}
        />
      </Grid>
    </Box>
  );
};

const getCategoryRequest = () => {
  return request({
    url: "/is_Visible",
  });
};

const Home = () => {
  const delay = 2;
    const [show, setShow] = useState(false);

    useEffect(
    () => {
      let timer1 = setTimeout(() => setShow(true), delay * 0);
      return () => {
        clearTimeout(timer1);
      };
    },
    []
  );
  const { ln } = useSelector((state) => state.langauge);

  const getOffersAndCategoriesQuery = useQueries({
    queries: [
      {
        queryKey: ["offers"],
        queryFn: getOffersRequest,
      },
      {
        queryKey: ["categories"],
        queryFn: getCategoryRequest,
      },
    ],
  });
 
  if (
    getOffersAndCategoriesQuery[0].isLoading ||
    getOffersAndCategoriesQuery[1].isLoading
  ) {
   // return <SkeltonLoader />;
 {/**   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' ,transition: 'transform 0.3s ease-in-out' }}>
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%',transition: 'transform 0.3s ease-in-out' }}>
    <div style={{ height: '90vh', backgroundImage: `url(${prearabic})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '80vw', height: '90vh',transition: 'transform 0.3s ease-in-out'  }}>
    </div>
</div>
</div>
*/}
  }

  if (getOffersAndCategoriesQuery[0].isError) {
    return (
      <GetErrorHandler
        error={getOffersAndCategoriesQuery[0].error}
        refetch={getOffersAndCategoriesQuery[0].refetch}
      />
    );
  }
  if (getOffersAndCategoriesQuery[1].isError) {
    return (
      <GetErrorHandler
        error={getOffersAndCategoriesQuery[1].error}
        refetch={getOffersAndCategoriesQuery[1].refetch}
      />
    );
  }

//   const backgroundImage = {
   
//     backgroundImage: `url(${prearabic})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     marginTop:'20px',
//     width: '60vw',
//     height: '60vh',
//     transition: 'transform 0.3s ease-in-out', 

// };

  // console.log(getOffersAndCategoriesQuery[0].data.data)
  return show ?
   (
    <Box
      sx={{
        // pt: 2,
        px: 0,
        direction: ln === "ar" ? "rtl" : "ltr",
//background:'#E2DACD '   
overflowY:'scroll',
overflowX:'none'
      }}
    >
  
    <Box
    sx={{
      background: '#E2DACD',
      width: '100vw', // Adjusted to 100vw to cover the entire viewport width
      height: '400vh', // Adjusted to 100vh to cover the entire viewport height
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      zIndex:'-3',
      overflowX:'none'
    }}
  ></Box>
      <Box
        sx={{
          width: {xs : '100%' , sm : "65%"},
          maxWidth: "400px",
          margin: "0 auto 24px auto",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Swiper
              // spaceBetween={50}
              slidesPerView={1}
              loop={true}
              // effect={"cards"}
              grabCursor={true}
              modules={[EffectCards, Autoplay]}
              autoplay={{
                delay: 2100,
                disableOnInteraction: false,
              }}
              style={{
                width: "100%",
              }}
            >
              {getOffersAndCategoriesQuery[0]?.data?.data.map((feature) => {
                return (
                  <SwiperSlide
                    key={feature.id}
                    style={{
                      // borderRadius: "11px",
                      // overflow: "hidden",
                    }}
                  >
                  <div
                  style={{
                    backgroundImage: `url(${baseImageUrl}${feature.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '30vh', // Set the desired height of the slide
                  }}
                >
              
                </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Grid>
        </Grid>
      </Box>

      <Typography
        sx={{
          fontSize: "34px",
          fontWeight: "bold",
          textAlign: "center",
          mb: "28px",
          color: "#612C19",
        }}
      >
        {ln === "ar" ? <h3 className="Niggar">القائمة</h3> : <h3 className="Arciform">Menu</h3> }
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={1} sx={{ mb: "74px" }} >
      {getOffersAndCategoriesQuery[1]?.data?.data
        ?.sort((a, b) => a.position - b.position)  // Sort by the position field
        .map((category) => {
                console.log(getOffersAndCategoriesQuery[1]?.data?.data,'getOffersAndCategoriesQuery')
          return (
            <Grid key={category.id} item xs={6} md={4}>
              <Box
                sx={{
                  textDecoration: "none",
                
                }}
                component={Link}
                to={`/home/${category.id}`}
              >
                <Box
                
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: {xs:'150px' , sm:'250px'},
                  }}
                  
                >
                  
                 <div
  style={{
    maxWidth: "100%", // Set the maximum width for the container
    marginBottom: "14px",
    borderRadius: "12px",
    width: "100%", // Set the width to 100% to make the box fill its parent
    overflow: "hidden", // Hide any content that overflows the box
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional: add shadow for a card-like effect
  }}
>
  <img
    src={`${baseImageUrl}${category.image}`}
    alt={category.name}
    style={{
      width: "100%", // Set the width of the image to 100% to fill the container
      height: "auto", // Maintain the aspect ratio of the image
      objectFit: "cover", // Cover the container while maintaining the aspect ratio
      minHeight: "100%", // Ensure the image covers the entire container
      objectPosition: "center", // Center the image within the container
      display: "block", // Remove extra space below the image
    }}
    loading="lazy"
  />
</div>
                </Box>
                <Typography
                // md={{fontSize: "5px"}}
                  sx={{
                    textAlign: "center",
                    color: "#1C5F55",
                    fontWeight: "bold",
                    fontSize: {xs:"10px" , sm:'14px'},
                    marginTop:"-3px",
                    marginBottom:"20px"
                    // whiteSpace:"nowrap", 
                  }}
                >
                  {ln === "ar" ?  
                  <h2 className="Niggar" style={{fontSize:"20px"}}>{category.ar_name}</h2> 
                  : <h2 className="Arciform" style={{fontSize:"20px"}}>{category.name}</h2>}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Typography
        sx={{
          color: "#612C19",
          fontSize: "19px",
          textAlign: "center",
          fontWeight: "bold",
          mb: "50px",
        }}
      >
        {ln === "ar"
          ? <h4 className="Niggar"> ملاحظة : قبل الطلب يرجى التأكد إذا كان هناك حساسية تجاه أي نوع من أنواع الطعام والمشروبات</h4>
          :  <h4 className="Arciform">Note : Before ordering, please check if you are allergic to any type of food or drink</h4>}
      </Typography>
      <div style={{display:'flex',justifyContent:'space-around',marginX:'3px',marginBottom:'30px'}}>
      <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "3px",
       
        bottom: "50px", 
        right: "10px",
       color:"black",
        fontWeight: "bold",
        paddingX:'10px'
      }}
    >
    <span style={{ 
      fontSize: "15px",
      fontWeight: "bold",
    }}>
      <a href="https://social.gomaksa.com/omar"
         target="_blank"
         style={{
           textDecoration: "none", // Removes underline
           color: "inherit", // Inherits the text color from the parent element
           display:"flex",
           alignItems:"center",
         }}>
         Powered By: GOMA
      </a>
    </span>
    </Box>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
       
        bottom: "10px",
        left: "10px",
     
        fontWeight: "bold",
      }}
    >
   {/**   <a
        href="https://instagram.com/gosh.ksa?igshid=MzRlODBiNWFlZA=="
        target="_blank"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Instagram
          fontSize="large"
          sx={{
            width: "30px",
            height: "30px",
          }}
        />
      </a>
      <a
        href="https://instagram.com/gosh.ksa?igshid=MzRlODBiNWFlZA=="
        target="_blank"
        style={{
          textDecoration: "none",
          color: "black",
          fontSize: "20px",
          fontWeight: "bold",
          display: "block",
        }}
      >
       
       <WhatsAppIcon
       fontSize="large"
       sx={{
         width: "30px",
         height: "30px",
       }}
     />
         </a>
         <a
         href="https://instagram.com/gosh.ksa?igshid=MzRlODBiNWFlZA=="
         target="_blank"
         style={{
           textDecoration: "none",
           color: "black",
           fontSize: "20px",
           fontWeight: "bold",
           display: "block",
         }}
       >
        
       <img src={image} style={{ width:'30px',height:'30px'}}/>

          </a>
          <a
          href="https://instagram.com/gosh.ksa?igshid=MzRlODBiNWFlZA=="
          target="_blank"
          style={{
            textDecoration: "none",
            color: "black",
            fontSize: "20px",
            fontWeight: "bold",
            display: "block",
            marginTop:'-5px'
          }}
        >
         
         <PinDropIcon
         fontSize="large"
         sx={{
           width: "30px",
           height: "30px",
         }}
       />
           </a>
          */}
          <Box
          sx={{
            display: "flex",
            alignItems: "end",
            gap: "5px",
            bottom: "10px",
            left: "10px",
            color: "black",
            fontWeight: "bold",
          }}
        >
        <a
          href="https://linktr.ee/goshksa"
          target="_blank"
          style={{
            textDecoration: "none",
            color: "black",
            fontSize: "20px",
            fontWeight: "bold",
            display: "block",
            marginTop:'-5px'
          }}
        >
        Social Medias
        </a>
        <PublicIcon
        fontSize="large"
        style={{ marginBottom:'7px'}}
        sx={{
          width: "25px",
          height: "25px",
        }}
      />
      
       </Box>
         
         </Box>
    </div>
    </Box>
  )
  : (
''
  );
};

export default Home;
