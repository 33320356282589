import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import { baseImageUrl, request } from "../../api/request";
import { useQuery } from "@tanstack/react-query";
import { GetErrorHandler } from "../../helper/GetErrorHandlerHelper";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import image from "../../assets/images/offer2.jpg";
import SingleProductModel from "../../components/SingleProductModel";
const GetCategoryRelations = (id) => {
  return request({
    url: `/categoriesSome/${id}`,
  });
};

const SkeltonLoader = () => {
  return (
    <Box
      sx={
        {
          // pt: 8,
          // pl: 2,
          // pr: 2,
        }
      }
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "65%" },
          margin: "0 auto 24px auto",
          maxWidth: "400px",
          margin: "0 auto 24px auto",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              width={"100%"}
              height={"300px"}
              sx={{
                borderRadius: "12px",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="40px"
            width={"100%"}
            sx={{
              borderRadius: "12px",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="40px"
            width={"100%"}
            sx={{
              borderRadius: "12px",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="40px"
            width={"100%"}
            sx={{
              borderRadius: "12px",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="40px"
            width={"100%"}
            sx={{
              borderRadius: "12px",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="40px"
            width={"100%"}
            sx={{
              borderRadius: "12px",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="40px"
            width={"100%"}
            sx={{
              borderRadius: "12px",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="40px"
            width={"100%"}
            sx={{
              borderRadius: "12px",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="40px"
            width={"100%"}
            sx={{
              borderRadius: "12px",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const CategoryProduct = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedproduct, setSelectedproduct] = useState(null);

  const { ln } = useSelector((state) => state.langauge);
  const { category } = useParams();
  const getOffersAndCategoriesQuery = useQuery({
    queryKey: ["get-categoryies-relationships"],
    queryFn: () => GetCategoryRelations(category),
  });

  const getOffersAndCategoriesData = getOffersAndCategoriesQuery?.data?.data;
  let desiredObject;
  // if (getOffersAndCategoriesData) {

  //   console.log(getOffersAndCategoriesData)
  //   desiredObject = getOffersAndCategoriesData?.find(
  //     (item) => item.id == category
  //   );

  //   if (desiredObject) {
  //     // Use the desiredObject here
  //     console.log(desiredObject);
  //   } else {
  //     console.log("Object with id 2 not found in sub_category array.");
  //   }
  // } else {
  //   console.log("No data available.");
  // }
  if (getOffersAndCategoriesQuery.isLoading) {
    return <SkeltonLoader />;
  }

  if (getOffersAndCategoriesQuery.isError) {
    return (
      <GetErrorHandler
        error={getOffersAndCategoriesQuery.error}
        refetch={getOffersAndCategoriesQuery.refetch}
      />
    );
  }
  function truncateText(text, maxLength) {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  }

  return (
    <Box
      sx={{
        // pt: 8,
        // pl: 2,
        // pr: 2,
        direction: ln === "ar" ? "rtl" : "ltr",
        //  background:'#E2DACD '  ,
        // width : {xs : '100%' , md : '80%' , lg : '50%'},
        // margin : {xs : '0 auto'}
       // overflowX:'hidden',
        overflowY:'scroll',
      //  width:"100vw"

      }}
    >
   
      <Box
        sx={{
          background: "#E2DACD",
          width: "120vw", // Adjusted to 100vw to cover the entire viewport width
          height: "400vh", // Adjusted to 100vh to cover the entire viewport height
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "-3",
          
        }}
      ></Box>

      <Box
        sx={{
          width: { xs: "100%", sm: "65%" },
          margin: "0 auto 24px auto",
          maxWidth: "500px",
          margin: "0 auto 24px auto",
          overflowX:'none',
        }}
      >
        <Box>
          <Swiper
            slidesPerView={1}
            loop={true}
            grabCursor={true}
            modules={[EffectCards, Autoplay]}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            style={{
              width: "100%",
            }}
          >
            {getOffersAndCategoriesData.feature.map((feat) => {
              return (
                <SwiperSlide key={feat.id}>
                <div
                  style={{
                    backgroundImage: `url(${baseImageUrl}${feat.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '30vh', // Set the desired height of the slide
                  }}
                >
              
                </div>
              </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Box>
      <Typography
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%" },
          margin: "0 auto",
          overflowX:'none',
        }}
      >
        {getOffersAndCategoriesData.sub_category.map((obj) => {
          console.log(getOffersAndCategoriesData);
          return (
            <Grid key={obj.id} item xs={10} md={6}
            style={{display:'flex',justifyContent:'center',   }}
            >
              <Typography
                sx={{
                  color: "#1C5F55",
                //  fontSize: "20px",
                  fontWeight: "bold",
                  textAlign: "center",
                  mb: "31px",
                  width:'90vw',
                     overflowX:'none',
                }}
              >
                {ln === "ar" ? (
                  <h2 className="Niggar" style={{fontSize:'27px', fontFamily:"stc_bold",                }}>{obj.ar_name}</h2>
                ) : (
                  <h2 className="Arciform" style={{fontSize:'27px', fontFamily:"stc_bold",                }}>{obj.name}</h2>
                )}
                <Grid container rowSpacing={2} columnSpacing={1}>
                  {obj.product.map((prod) => {
                    return (
                      <Grid
                      
                        item
                        xs={13}
                        sm={6}
                        md={6}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: " 15px",
                          alignItems: "center",
                          margin: "14px 0",
                        }}
                        
                      >
                        <Typography
                        onClick={()=>{isModalOpen==false?setIsModalOpen(true):'',setSelectedproduct(prod)}}

                          sx={{
                            color: "#612C19",
                            fontSize: { xs: "12px", md: "15px" },
                            margin: "0 0",
                            background: "#f7f7f7",
                            width: "100%",
                            height: "90%",
                            display: "flex",
                            justifyContent: "space-between",
                            borderRadius: "10px",
                            padding: " 15px",
                            position: "relative",
                            alignItems: "flex-start",
                            boxShadow: "0 8px 19px 0 #ab8d7b",
                            minHeight: "120px",
                          }}
                        >
                          <div
                            className=""
                            style={{
                              marginLeft: ln === "ar" ? "120px" : "0",
                              marginRight: ln !== "ar" ? "120px" : "0",
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              height: "100%",
                           
                            }}
                          >
                            <div style={{   marginBottom:'30px',}}>
                              {" "}
                              {ln === "ar" ? (
                                <h3
                                  className="Niggar"
                                  style={{
                                    display: "flex",
                                    fontSize: "17px ",
                                    position: "absolute",
                                    top: "-6px",
                                    right: "-5px",
                                    whiteSpace:'nowrap',
                                  
fontFamily:"stc_bold",


                                  }}
                                  
                                >
                                {prod?.ar_name}
                                  
                                </h3>
                              ) : (
                                <h4
                                  className="Arciform"
                                  style={{
                                    display: "flex",
                                    textAlign: "right",
                                    fontSize: "18px",
                                    whiteSpace:'nowrap'
                                  }}
                                >
                                {prod?.name}
                                  
                                </h4>
                              )}
                              {ln === "ar" ? (
                                <h4
                                  style={{
                                    display: "flex",
                                    fontSize: "15px",
                                    marginTop: "20px",
                                
                                  }}
                                >
                                  {truncateText(prod?.description_arabic, 15)}
                                </h4>
                              ) : (
                                <h3
                                  className="Arciform"
                                  style={{ display: "flex", textAlign: "left" }}
                                >
                                  {truncateText(prod?.description, 15)}
                                </h3>
                              )}
                            </div>
                            <div
                              style={{
                                width:'150px',
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "0.3rem",
                              }}
                            >
                            <h4>  <span style={{color:'#1C5F55',  fontFamily:"stc_bold"}}>{prod.price}</span> {prod.price && ln=='ar'?' ريال ':prod.price && ln!='ar'?'SAR':''} </h4>
                      {/**       <h4> <span style={{color:'#1C5F55',fontFamily:"stc_bold"}}>{prod.calories}</span>{prod.calories && ln=='ar'?' سعرة حرارية ':prod.calories && ln!='ar'?'CAL':''}</h4>
                         */}
                            </div>
                          </div>
                          <div>
                            {ln === "ar" ? (
                              <div
                                style={{
                                  width: "120px",
                                  height: "120px",
                                  //  maxWidth: "120px", // Set the maximum width for the container
                                  borderRadius: "10px", // Rounded corners
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow effect
                                  marginRight: "5px", // Margin to the right
                                  overflow: "hidden", // Hide any content that overflows the box
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%", // Set the width of the image to 100% to fill the container
                                    height: "100%", // Maintain the aspect ratio of the image
                                    objectFit: "cover", // Cover the container while maintaining the aspect ratio
                                    borderRadius: "10px", // Rounded corners for the image
                                    display: "block", // Remove extra space below the image
                                  }}
                                  src={`https://api.goshm.gomaplus.tech/${prod.image}`}
                                  alt="Product"
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "120px",
                                  height: "120px",
                                  //  maxWidth: "120px", // Set the maximum width for the container
                                  borderRadius: "10px", // Rounded corners
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow effect
                                  marginLeft: "5px", // Margin to the right
                                  overflow: "hidden", // Hide any content that overflows the box
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%", // Set the width of the image to 100% to fill the container
                                    height: "100%", // Maintain the aspect ratio of the image
                                    objectFit: "cover", // Cover the container while maintaining the aspect ratio
                                    borderRadius: "10px", // Rounded corners for the image
                                    display: "block", // Remove extra space below the image
                                  }}
                                  src={`https://api.goshm.gomaplus.tech/${prod.image}`}
                                  alt="Product"
                                />
                              </div>
                            )}
                          </div>
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Typography>
            </Grid>
          );
        })}
        <div style={{ position: "fixed", bottom: "10px", left: "-35px" }}>
          {ln === "ar" ? (
            <box
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "0 46px",

                margin: "20px 0", //   alignItems : 'center',
                //   width:'100vw'
              }}
            >
              <Link
                to={"/home"}
                style={{
                  backgroundColor: "#305E55 ",
                  padding: "10px 20px",
                  borderRadius: "20px",
                  color: "white",
                  display: "flex",
                  height: "40px",
                  justifyContent: "end",
                  textDecoration: "none",
                  
                  //marginTop:'-2px',
                }}
              >
                <h4 className="Niggar  "style={{ marginTop:'-2px'}}>القائمة الرئيسية </h4>

          {/**     <span
                  style={{
                    color: "white",
                    fontSize: "20px",
                    marginRight: "10px",
                    marginTop: "-6px",
                  }}
                >
                  {">"}
                </span>
              */}
              </Link>
            </box>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                padding: "0 46px",

                margin: "20px 0",
              }}
            >
              <Link
                to={"/home"}
                style={{
                  backgroundColor: "#305E55 ",
                  padding: "10px 20px",
                  borderRadius: "20px",
                  color: "white",
                  display: "flex",
                  justifyContent: "end",
                  textDecoration: "none",
                }}
              >
           {/**    <span
                  style={{
                    color: "white",
                    fontSize: "20px",
                    marginRight: "10px",
                    marginTop: "-6px",
                  }}
                >
                  {"<"}
                </span>*/} 
                <h4 className="Arciform" style={{ marginTop:'-2px'}}>Main Menu</h4>
              </Link>
            </div>
          )}
        </div>
      </Typography>
      {isModalOpen&&
        <div > 
        <SingleProductModel selectedproduct={selectedproduct} onClose={()=>{setIsModalOpen(false)}} />
        </div>
       }
    </Box>
  );
};

export default CategoryProduct;
