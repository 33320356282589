import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ln : 'ar'
}

const language = createSlice({
    name : 'language',
    initialState,
    reducers : {
        to_ar : (state) => {
            state.ln = 'ar'
        },
        to_en : (state) => {
            state.ln = 'en'
        },
    }
})

export default language

export const {to_ar , to_en} = language.actions