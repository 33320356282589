import { Box, ButtonBase, Typography } from "@mui/material";
import React from "react";
import Background from "../../assets/images/startpagebackground.jpg";
import Logo from "../../assets/images/logo 2.png";
import { useNavigate } from "react-router";
import { Instagram } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { to_ar, to_en } from "../../store/slice";
import { useMutation } from "@tanstack/react-query";
import { request } from "../../api/request";
import PublicIcon from '@mui/icons-material/Public';

const StartingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const visitMutation = useMutation({
    mutationKey: ["create-visit"],
    mutationFn: () => {
      return request({
        url: "/visits",
        method: "post",
      });
    },
  });

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "relative",
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
      }}
    >
      <Box
        sx={{
          pl: 2,
          pr: 2,
          mb: 8,
        }}
      >
        <img
          src={Logo}
          alt="logo"
          style={{
            maxWidth: "100%",
          }}
        />
      </Box>

      <ButtonBase
        sx={{
          bgcolor: "white",
          borderRadius: "20px",
          padding: "6px 20px",
          mb: 3,
          color: "#1C5F55",
          width: "248px",
          height: "64px",
          fontSize: "35px",
        }}
        onClick={() => {
          dispatch(to_ar());
          visitMutation.mutate();
          navigate("/home");
        }}
      >
        <h3 className="Somar">العربية</h3>
      </ButtonBase>
      <ButtonBase
        sx={{
          bgcolor: "white",
          color: "#1C5F55",
          borderRadius: "20px",
          padding: "6px 20px",
          mb: 3,
          width: "248px",
          height: "64px",
          fontSize: "35px",
        }}
        onClick={() => {
          dispatch(to_en());
          visitMutation.mutate();
          navigate("/home");
        }}
      >
        <h3 className="Somar">English</h3>
      </ButtonBase>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          position: "absolute",
          bottom: "50px",
          right: "10px",
          color: "#fff",
          fontWeight: "bold",
        }}
      >
     
      <span style={{ 
        fontSize: "15px",
        fontWeight: "bold",
      }}>
        <a href="https://social.gomaksa.com/omar"
           target="_blank"
           style={{
             textDecoration: "none", // Removes underline
             color: "inherit", // Inherits the text color from the parent element
           }}>
          Powered By: GOMA
        </a>
      </span>
      
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          position: "absolute",
          bottom: "40px",
          left: "10px",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <a
          href="https://linktr.ee/goshksa"
          target="_blank"
          style={{ textDecoration: "none", color: "white" }}
        >
          <PublicIcon
            fontSize="large"
            sx={{
              width: "30px",
              height: "30px",
            }}
          />
        </a>
        <a
          href="https://linktr.ee/goshksa"
          target="_blank"
          style={{
            textDecoration: "none",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            display: "block",
            marginTop:'-5px'
          }}
        >
        Social Medias
        </a>
      </Box>
    </Box>
  );
};

export default StartingPage;
