import React from 'react'
import { Route, Routes } from 'react-router'
import StartingPage from '../pages/Start'
import Home from '../pages/Home'
import CategoryProduct from '../pages/CategoryProduct'

const AppRoutes = () => {
  return (
    <Routes>
        <Route path='/' element={<StartingPage />} />
        <Route path='/home' element={<Home />} />
        <Route path='/home/:category' element={<CategoryProduct />} />
    </Routes>
  )
}

export default AppRoutes