import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';

function SingleProductModel({selectedproduct,onClose}) {
    const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
    const { ar_name, image, name, description_arabic, calories, price ,description} = selectedproduct;
    const { ln } = useSelector((state) => state.langauge);

    console.log('hehehehehe',selectedproduct)
  return (
    <>
    <div style={{width:'1000%',height:'1000%',zIndex:'10',position:'absolute' ,top:0,right:'0',background:'black',opacity:'50%',bottom:0}}></div>
    <div className="modal" style={{backgroundColor:'#f8f8f8',zIndex:'11'}} ref={dropdownRef}>
   
  
    <div style={{ textAlign: 'center', marginTop: '10px' }}>
    <div className="product-image" style={{ display:'flex',
    justifyContent:'center'}}>
    <div
    style={{
      width: "200px",
      height: "200px",
      //  maxWidth: "120px", // Set the maximum width for the container
      borderRadius: "10px", // Rounded corners
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow effect
      marginRight: "7px", // Margin to the right
      overflow: "hidden", // Hide any content that overflows the box
    
     
    }}
  >
    <img
      style={{
        width: "100%", // Set the width of the image to 100% to fill the container
        height: "100%", // Maintain the aspect ratio of the image
        objectFit: "cover", // Cover the container while maintaining the aspect ratio
        borderRadius: "10px", // Rounded corners for the image
        display: "block", // Remove extra space below the image
      }}
      src={`https://api.goshm.gomaplus.tech/${image}`}
      alt="Product"
    />
  </div>
      </div>
    <div style={{ marginTop: '20px' }}> 
    {
        ln=='ar'?  
      <h2 style={{color:'#612C19'}}>{ar_name}</h2>
      :   <h2 style={{color:'#612C19'}}>{name}</h2>

    }
    {
        ln=='ar'?  
      <p style={{color:'#612C19',marginTop:'10px'}}>{description_arabic}</p>
      :   <p style={{color:'#612C19',marginTop:'10px'}}>{description}</p>

    }
     
      <div className="product-details" style={{display:'flex' ,justifyContent:'space-around',marginTop:'10px',color:'#612C19'}}>
      <span>  <span style={{color:'#1C5F55'}}>{price}</span> {price && ln=='ar'?'ريال ':price && ln!='ar'?'SAR':''} </span>
       {/**  <span>  <span style={{color:'#1C5F55'}}>{calories}</span> {calories && ln=='ar'?'سعرة حرارية':calories && ln!='ar'?'CAL':''}</span>
     */}
      </div>
    </div>
  </div>
   
  </div>
  </>  )
}

export default SingleProductModel
